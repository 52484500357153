import React from "react"

import LayoutScene from "../components/layout-scene"
import IndexPage from "./resume"
import SEO from "../components/seo"



import Scene from "../components/scenes/city";

// const onIdle = e => {
//
// }

const Interactive = () => (
    <LayoutScene>

        {/*<IdleTimer onIdle={onIdle} timeout={600}>*/}
        {/*    <div>*/}
        {/*        <div*/}
        {/*            style={{*/}
        {/*                margin: '0 auto',*/}
        {/*                maxWidth: 960,*/}
        {/*                padding: '0px 1.0875rem 1.45rem',*/}
        {/*                paddingTop: 0,*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <p>Hahahah</p>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</IdleTimer>*/}

        <SEO title="WebGL" />


        <div style={{position:`relative`, margin: `0`, padding:`0`, border:`0`}}>
            <Scene />
        </div>
    </LayoutScene>
)

export default Interactive